<template>
  <div>
    <div style="border: 1px solid #ccc;">
      <!-- 工具栏 -->
      <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
      />
      <!-- 编辑器 -->
      <Editor
          style="height: 500px; overflow-y: scroll"
          :defaultConfig="editorConfig"
          v-model="html"
          @onChange="onChange"
          @onCreated="onCreated"
      />
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import config from "@/config/config";
import {getToken} from "@/utils/auth";
import axios from "axios";

export default {
  name: "MyEditor",
  components: { Editor, Toolbar },
  data() {
    return {
      uploadUrl: config.HTTP_REQUEST_URL + 'admin/1.0/common/uploadimg',
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: 'Bearer ' + getToken()
      },
      picdata: {
        type: 'file'
      },
      editor: null,
      html: "",
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        excludeKeys: [
            'group-video'
        ],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        // autoFocus: false,

        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            customUpload: async (file, insertFn) => {
              console.log(file)
              console.log(insertFn)
              const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
              const isLt2M = file.size / 1024 / 1024 < 5;
              if (!isJPG) {
                this.$message.error('上传图片格式不支持!');
              }
              if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
              }
              const formData = new FormData();
              formData.append('type', 'file')
              formData.append('file', file);
              axios.post(this.uploadUrl, formData, {
                headers: this.headers
              })
              .then(response => {
                if(response.data.errcode==0) {
                  insertFn(response.data.data.url);
                } else {
                  this.$message.error(response.data.errmsg);
                }
              })
              .catch(error => {
                this.$message.error('上传失败');
                // 处理上传失败事件
              });
              // let resultUrl = await this.upqiniu(file, file.name);
              // insertFn(resultUrl);
            },
          },
          uploadVideo: {
            customUpload: async (file, insertFn) => {
              // let resultUrl = await this.upqiniu(file, file.name);
              // insertFn(resultUrl);
            },
          },
          fontFamily: {
            fontFamilyList: [
              { name: '阿里巴巴普惠体 3.0', value: 'AlibabaPuHuiTi-3-55-Regular' },
              '黑体',
              '仿宋',
              '楷体',
              '标楷体',
              '华文仿宋',
              '华文楷体',
              '宋体',
              '微软雅黑',
              'Arial',
              'Tahoma',
              'Verdana',
              'Times New Roman',
              'Courier New'
            ]
          }
        },
      },
    };
  },
  props: {
    txt: String,
    idx: Number,
    callback: Function
  },
  watch: {
    txt(newVal) {
      this.html = newVal
      // if(this.isEdit) {
      //   if(newVal) {
      //     this.isEdit = false
      //   }
      //   this.content = newVal
      //   this.editor.txt.html(newVal)
      // }
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
    },
    onChange(editor) {
      console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
      this.callback(editor.getHtml(), this.idx)
    },
    getEditorText() {
      const editor = this.editor;
      if (editor == null) return;

      console.log(editor.getText()); // 执行 editor API
    },
    printEditorHtml() {
      const editor = this.editor;
      if (editor == null) return;

      console.log(editor.getHtml()); // 执行 editor API
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.html = "<p>Ajax 异步设置内容 HTML</p>";
    // }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>
<style>
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
